<div class="title-bar mb-3">
  <div class="row">
    <div class="col-md-5 d-flex align-items-center">
      <h2>{{ title | translate }}</h2>
    </div>
    <div class="col-md-7 d-flex justify-content-end">
      @if (isSearchEnabled) {
        <app-search-box (searchRequest)="requestSearch($event)"></app-search-box>
      }
      @if(isColumsConfigurable){
        <app-customize-button [columns]="coloums" (toggleRquest)="requestToggleColoumn($event)"></app-customize-button>
      }
      @if(isQuickSortEnabled){
        <app-sort-dropdown [items]="quickSortItems"></app-sort-dropdown>
      }
      @if(moreItems.length>0){
        <app-more-dropdown 
        [enableItems]="moreItems"
        (templateClick)="requestMoreItemTemplate()"
        (importClick)="requestMoreItemImport()"
        (exportClick)="requestMoreItemExport()"
        (archiveClick)="requestMoreItemArchive()"
        (deleteClick)="requestMoreItemDelete()"
        (refreshClick)="requestMoreItemRefresh()"
        ></app-more-dropdown>
      }
      @if(isAddNewEnabled){
        <app-add-new-button [label]="addButtonLabel | translate" (addRequest)="requestAdd()"></app-add-new-button>
      }
      @if(isEditButtonEnabled){
        <app-edit-button [label]="editButtonLabel | translate" (editRequest)="requestEdit()"></app-edit-button>
      }
      @if(isDeleteButtonEnabled){
        <app-delete-button [label]="deleteButtonLabel | translate" (deleteRequest)="requestDelete()"></app-delete-button>
      }
      @if(isSubmitButtonEnabled){
        <button class="btn btn-primary btn-theme ms-1" type="button" (click)="requestSubmit()">Submit</button>
      
      }
      @if(isCustomButtonEnabled){
        <app-button [type]="customButtonType" [isLoading]="customButtonLoading" [loadingLabel]="customButtonLoadingLabel" [label]="customButtonLabel" (clickRequest)="requestClick()"></app-button>
      }
    </div>
  </div>
</div>
